import React, {useState} from "react";
import { Link } from "react-router-dom";
import ForgotPasswordFav from "../Assets/favicon.png";
import { FaLock, FaRegEyeSlash, FaRegEye } from "react-icons/fa";

function ResetPassword (){
  const [password, setPassword] = useState(false);
  const [ConfirmPassword, setConfirmPassword] = useState(false);

    return (
        <>
        <div className="w-full h-screen flex items-center justify-center flex-col">
        <div className="w-[33%] xl:w-[90%] bg-white shadow hover:shadow-lg rounded-md">
          <div className="bg-theme-color py-4 rounded-t-md">
            <h1 className="text-center text-[36px] 2xl:text-[30px] font-poppins font-bold text-white mb-2">Reset Password</h1>
            <p className="text-center text-white text-xl 2xl:text-base mb-3 font-poppins">
            Strong password include number, letter, and punctuation Marks.
            </p>
            <img src={ForgotPasswordFav} className="w-12 h-12 bg-white rounded-[50%] p-2 m-auto -mb-8 shadow-[0_3px_5px_#5690F6]" alt="favicon"></img>
          </div>
          <div className="p-14 md:p-8">
            <form>
                <label className="relative block mb-4 w-full">
                  <span className="absolute inset-y-0 left-0 flex items-center pl-2">
                    <FaLock className="h-4 w-4 fill-theme-color" />
                  </span>
                  <input
                    type={password === true ? "text" : "password"}
                    className="mt-1 block w-full pr-8 pl-8 py-3 bg-white border border-theme-color rounded-md text-sm shadow-sm placeholder-slate-400 focus:outline-none focus:border-[#5690F6] focus:ring-1 focus:ring-[#5690F6] invalid:border-red-500 invalid:text-red-600 focus:invalid:border-red-500 focus:invalid:ring-red-500 font-poppins"
                    placeholder="Password"
                  />
                  <span
                    className="absolute inset-y-0 right-0 flex items-center pr-2 cursor-pointer"
                    onClick={() => setPassword(!password)}
                  >
                    {password === true ? (
                      <FaRegEye className="h-5 w-5 fill-theme-color" />
                    ) : (
                      <FaRegEyeSlash className="h-5 w-5 fill-theme-color" />
                    )}
                  </span>
                </label>
                <label className="relative block mb-4 w-full">
                  <span className="absolute inset-y-0 left-0 flex items-center pl-2">
                    <FaLock className="h-4 w-4 fill-theme-color" />
                  </span>
                  <input
                    type={ConfirmPassword === true ? "text" : "password"}
                    className="mt-1 block w-full pr-8 pl-8 py-3 bg-white border border-theme-color rounded-md text-sm shadow-sm placeholder-slate-400 focus:outline-none focus:border-[#5690F6] focus:ring-1 focus:ring-[#5690F6] invalid:border-red-500 invalid:text-red-600 focus:invalid:border-red-500 focus:invalid:ring-red-500 font-poppins"
                    placeholder="Confirm Password"
                  />
                  <span
                    className="absolute inset-y-0 right-0 flex items-center pr-2 cursor-pointer"
                    onClick={() => setConfirmPassword(!ConfirmPassword)}
                  >
                    {ConfirmPassword === true ? (
                      <FaRegEye className="h-5 w-5 fill-theme-color" />
                    ) : (
                      <FaRegEyeSlash className="h-5 w-5 fill-theme-color" />
                    )}
                  </span>
                </label>
              <div className="flex justify-center mt-12 mb-7">
                <button type="submit" className="bg-theme-color text-white p-2 w-80 rounded-lg font-poppins font-bold">
                  Update
                </button>
              </div>
              <div className="text-center">
                <p className="text-xl 2xl:text-sm font-poppins">Remember It ? <Link to="/" className="text-theme-color font-medium underline" rel="noreferrer">Sign In here</Link></p>
              </div>
            </form>
          </div>
        </div>
        <span className="font-poppins mt-4">© 2023 Event Web by <Link to="https://www.parangat.com/" className="text-theme-color underline" target="_blank" rel="noreferrer noopener">Parangat Technologies</Link></span>
      </div>
        </>
    )
}

export default ResetPassword;