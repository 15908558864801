import React, { createContext, useState, useEffect } from "react";
import { useNavigate, useMatch } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export const DataContext = createContext();

const DataProvider = ({ children }) => {
  // Server IP
  const BASE_URL = "https://api.event-plus.in/api/";


  // Local Server Ip
  // const BASE_URL = "http://192.168.0.108:4000/";


  const navigate = useNavigate();
  const [resultLoginApi, setResultLoginApi] = useState("");
  const [globalLoader, setGlobalLoader] = useState(false);
  const [buttonLoader, setButtonLoader] = useState(false)
  const [dashboardDataState, setDashboardDataState] = useState();
  const [addSpeaker, isAddSpeaker] = useState(false);
  const [resultEventListApi, setResultEventListApi] = useState();
  const [resultTrackEventList, setResultTrackEventList] = useState();
  const [eventDetails, setEventDetails] = useState(true);
  const [eventSpeakers, setEventSpeakers] = useState(false);
  const [eventSponsors, setEventSponsors] = useState(false);
  const [agendaDatesState, setAgendaDatesState] = useState();
  const [eventSpeakerState, setEventSpeakerState] = useState();
  const [eventSponsersState, setEventSponsorsState] = useState();
  const [eventAdminState, setEventAdminState] = useState();
  const [checkLogin, setCheckLogin] = useState(false);
  const [logout, setLogout] = useState(false)
  const [getAgendaSessionState, setGetAgendaSessionState] = useState();
  const [deleteBtn, setDeleteBtn] = useState(false);
  const [deleteBtnId, setDeleteBtnId] = useState("");


  // Login API
  const LoginApi = (state) => {
    setButtonLoader(true)
    var myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      email: state.email,
      password: state.password,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(BASE_URL + "user/login", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.statusCode === 200) {
          toast.success(result.message);
          setButtonLoader(false);
          localStorage.setItem("token", result.token);
          setTimeout(() => {
            navigate("/dashboard");
          }, 1000);
        } else {
          setButtonLoader(false);
          setResultLoginApi(result.message);
        }
      })
      .catch((error) => console.log("error", error));
  };

  // Dashboard Data In Number 

  const dashboardDataApi = () => {
    setGlobalLoader(true);
    let userToken = localStorage.getItem('token');
    var myHeaders = new Headers();
    myHeaders.append("token", userToken);

    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };

    fetch(BASE_URL + "event/countEvents/64a26d8351366cf128d2f0ff", requestOptions)
      .then(response => response.json())
      .then(result => {
        if (result.statusCode === 200) {
          setDashboardDataState(result.Result);
          setGlobalLoader(false);
        }
      })
      .catch(error => console.log('error', error));
  };


  //Show Event All events
  const EventAllListApi = (eventCateory) => {
    setGlobalLoader(true)
    var myHeaders = new Headers();
    myHeaders.append("token", localStorage.getItem("token"));
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      admin: [],
      event_type: eventCateory,
      isActive: true,
      isPublished: true,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(BASE_URL + "event/allEvents", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.statusCode === 200) {
          setResultEventListApi(result.Result);
          setGlobalLoader(false);
        }
      })
      .catch((error) => console.log("error", error));
  };


  // fetching event listing track

  const EventTrackListApi = (eventCateory) => {
    setGlobalLoader(true);
    var myHeaders = new Headers();
    myHeaders.append("token", localStorage.getItem("token"));
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      admin: [],
      event_type: eventCateory,
      isActive: true,
      isPublished: false,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(BASE_URL + "event/allEvents", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.statusCode === 200) {
          setResultTrackEventList(result.Result);
          setGlobalLoader(false);
        }
      })
      .catch((error) => console.log("error", error));
  };

  // Create Event For Admin
  const RegisterEventApi = (state) => {
    setButtonLoader(false);
    let userToken = localStorage.getItem('token');
    var myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("token", userToken);

    var raw = JSON.stringify({
      "eventName": state.eventName,
      "email": state.userEmail,
      "Name": state.userName,
      "password": state.password,
      "eventStartDate": state.startDate,
      "eventEndDate": state.endDate,
      "event_venue": state.eventLocation,
      "business": state.userBusinessName,
      "phone": state.userPhoneNumnber
    });

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };
    fetch(BASE_URL + "event/createEvent", requestOptions)
      .then(response => response.json())
      .then(result => {
        if (result.statusCode === 200) {
          setButtonLoader('false')
          toast.success(result.message);
          setTimeout(() => {
            navigate('/event');
          }, 1000);
        }
        if (result.statusCode === 410) {
          setButtonLoader('false')
          toast.warning(result.message);
        }
      })
      .catch(error => console.log('error', error));
  };


  // Get dates for Agenda
  const AgendaDates = (id) => {
    var requestOptions = {
      method: 'GET',
      redirect: 'follow'
    };
    fetch(BASE_URL + `event/noOfDaysinEvent/${id}`, requestOptions)
      .then(response => response.json())
      .then(result => {
        if (result.statusCode === 200) {
          setAgendaDatesState(result.Result)
        }
      })
      .catch(error => console.log('error', error));
  };



  // Get Session According to Dates
  const getSessionApi = (id, date) => {
    setGlobalLoader(true)
    let userToken = localStorage.getItem('token');
    var myHeaders = new Headers();
    myHeaders.append("token", userToken);
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      "date": date
    });

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };
    fetch(BASE_URL + `agenda/getagenda/${id}`, requestOptions)
      .then(response => response.json())
      .then(result => {
        if (result.statusCode === 200) {
          setGetAgendaSessionState(result.Result);
          setGlobalLoader(false);
        }
      })
      .catch(error => console.log('error', error));
  };

  // Get Event Speakers 
  const eventSpeakerApi = (id) => {
    setGlobalLoader(true)
    let userToken = localStorage.getItem('token');
    var myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("token", userToken);


    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };
    fetch(BASE_URL + `speaker/getSpeaker/${id}`, requestOptions)
      .then(response => response.json())
      .then(result => {
        if (result.statusCode === 200) {
          setEventSpeakerState(result.Result);
          setGlobalLoader(false)
        }
      })
      .catch(error => console.log('error', error));
  };

  // Get Event Sponsers
  const eventSponserApi = (id) => {
    let userToken = localStorage.getItem('token');
    var myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("token", userToken);

    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };
    fetch(BASE_URL + `sponsor/getSponsor/${id}`, requestOptions)
      .then(response => response.json())
      .then(result => {
        if (result.statusCode === 200) {
          setEventSponsorsState(result.Result)
          //console.log(eventSponsersState)
        }
      })
      .catch(error => console.log('error', error));
  }


  // Get All Events Admin And their Data
  const EventAdminApi = () => {
    setGlobalLoader(true);
    let userToken = localStorage.getItem('token');
    var myHeaders = new Headers();
    myHeaders.append("token", userToken);

    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };
    fetch(BASE_URL + `user/users`, requestOptions)
      .then(response => response.json())
      .then(result => {
        if (result.statusCode === 200) {
          setEventAdminState(result.Result);
          setGlobalLoader(false);
        }
      })
      .catch(error => console.log('error', error));
  }

  // sidebar

  useEffect(() => {
    if (
      window.location.pathname === "/" ||
      window.location.pathname === "/signup"

    ) {
      localStorage.removeItem("userInfo");
    } else if (
      window.location.pathname === "/reset-password"
    ) {
      setCheckLogin(false);
    } else if (

      window.location.pathname === "/forgot-password" ||
      window.location.pathname === "/reset-password" ||
      window.location.pathname === "/terms-condition" ||
      window.location.pathname === "/privacy-policy" ||
      window.location.pathname === "/dashboard" ||
      window.location.pathname === "/event" ||
      window.location.pathname === "/speaker" ||
      window.location.pathname === "/sponsors" ||
      window.location.pathname === "/notification" ||
      window.location.pathname === "/chat" ||
      window.location.pathname === "/create-event" ||
      window.location.pathname === "/register-event" ||
      window.location.pathname === "/event-details" ||
      window.location.pathname === "/admin"




    ) {
      setCheckLogin(true);
    } else {
      setCheckLogin(false);
    }
  }, [window.location.pathname]);


  return (
    <DataContext.Provider
      value={{
        LoginApi,
        resultLoginApi,
        setResultLoginApi,
        dashboardDataApi,
        globalLoader,
        setGlobalLoader,
        addSpeaker,
        isAddSpeaker,
        EventAllListApi,
        EventTrackListApi,
        resultEventListApi,
        eventDetails,
        setEventDetails,
        eventSpeakers,
        setEventSpeakers,
        eventSponsors,
        setEventSponsors,
        RegisterEventApi,
        AgendaDates,
        agendaDatesState,
        getSessionApi,
        eventSpeakerApi,
        eventSpeakerState,
        eventSponserApi,
        eventSponsersState,
        EventAdminApi,
        eventAdminState,
        resultTrackEventList,
        buttonLoader,
        setButtonLoader,
        checkLogin,
        setCheckLogin,
        logout,
        setLogout,
        getAgendaSessionState,
        setGetAgendaSessionState,
        dashboardDataState,
        deleteBtn,
        setDeleteBtn,
        deleteBtnId,
        setDeleteBtnId,
      }}
    >
      {children}
    </DataContext.Provider>
  );
};

export default DataProvider;
