import { React, useContext } from "react";
import { BsArrowLeftShort } from "react-icons/bs";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Agenda from "./Agenda";
import EventSpeaker from "./EventSpeaker";
import EventSponsors from "./EventSponsors";
import { DataContext } from "../../context/DataState";
import { useEffect } from "react";

const EventDetails = () => {
  const {
    eventDetails,
    setEventDetails,
    eventSpeakers,
    setEventSpeakers,
    eventSponsors,
    setEventSponsors,
    soloEventDetail
  } = useContext(DataContext);
  const navigate = useNavigate();

  const eventDetail = (JSON.parse(localStorage.getItem("eventDetail")));

  return (
    <>
      <div className="flex w-4/5">
        <div className="w-full px-6 py-4 h-screen overflow-y-scroll bg-theme-bg">
          <div className="flex gap-6 border-b py-4">
            <BsArrowLeftShort className="text-[30px] border cursor-pointer" onClick={() => navigate('/event')} />
            <h1 className="text-[20px] font-medium">{eventDetail.eventName}</h1>
          </div>

          <div className="">
            <ul className="flex gap-4 py-4 font-normal">
              <li
                onClick={() => {
                  { setEventDetails(true); setEventSpeakers(false); setEventSponsors(false) };
                }}
              >
                <p className={`${eventDetails ? "text-theme-color underline" : ""} hover:text-theme-color hover:underline mb-2 text-text-grey 4xl:text-[14px] text-[12px] cursor-pointer `}>
                  Agenda
                </p>
              </li>
              <li
                onClick={() => {
                  { setEventDetails(false); setEventSpeakers(true); setEventSponsors(false) };
                }}
              >
                <p className={`${eventSpeakers ? "text-theme-color underline" : ""} hover:text-theme-color hover:underline mb-2 text-text-grey 4xl:text-[14px] text-[12px] cursor-pointer`}>
                  Speakers
                </p>
              </li>
              <li
                onClick={() => {
                  { setEventDetails(false); setEventSpeakers(false); setEventSponsors(true) };
                }}
              >
                <p className={`${eventSponsors ? "text-theme-color underline" : ""} hover:text-theme-color hover:underline mb-2 text-text-grey 4xl:text-[14px] text-[12px] cursor-pointer`}>
                  Sponsors
                </p>
              </li>
            </ul>
          </div>
          {
            eventDetails ? <Agenda /> : eventSpeakers ? <EventSpeaker /> : eventSponsors ? <EventSponsors /> : <Agenda />
          }
        </div>
      </div>
    </>
  );
};

export default EventDetails;
