import React from "react";
import { useContext } from "react";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import profImg from '../../components/Assets/speaker2.jpg'
import { DataContext } from "../../context/DataState";
import noAgenda from '../Assets/No Results@3x.png';

const EventSpeaker = () => {
  const { id } = useParams();
  const { eventSpeakerApi, eventSpeakerState, globalLoader } = useContext(DataContext);
  const eventDetail = (JSON.parse(localStorage.getItem("eventDetail")))
  useEffect(() => {
    eventSpeakerApi(eventDetail._id)
  }, [])
  return (
    <div className={`grid ${eventSpeakerState?.length !== 0 ? 'grid-cols-4' : <></>} 3xl:gap-6 gap-8 place-content-center place-items-center`}>
      {
        globalLoader ? <div id="container" className="absolute top-1/2 left-1/2 -translate-x-1/2 ">
          <div className="flex gap-2 items-center">
            <div id="ball-1" className="circle"></div>
            <div id="ball-2" className="circle"></div>
            <div id="ball-3" className="circle"></div>
          </div>
        </div>
          :
          eventSpeakerState &&
            eventSpeakerState?.length !== 0 ? eventSpeakerState?.map((item) => (
              <div className="card bg-white p-4 flex flex-col justify-center items-center text-center rounded-xl h-full">
                <div className="w-[160px] h-[160px] rounded-full mb-6">
                  <img src={item?.image} className="w-full h-full rounded-full" alt="" />
                </div>
                <div className="flex flex-col">
                  <p className="text-black 3xl:text-[16px] font-normal pb-2">{item?.title}{item?.speaker_firstname}</p>
                  <p className="font-normal 3xl:text-[11px] text-[8px]">{item?.about_speaker}</p>
                  <p className="font-normal 3xl:text-[11px] text-[8px]">{item?.Biography}</p>
                </div>
              </div>
            )) : <>
            <div className="flex justify-center items-start w-full h-[70vh]">
              <img src={noAgenda} alt="" className="w-[300px] mt-24" />
            </div>
          </>
      }

    </div>
  );
};

export default EventSpeaker;
