import { React, useContext, useState } from "react";
import { RiTimer2Fill } from "react-icons/ri";
import { BiMinus } from "react-icons/bi";
import { TbChevronsDownLeft } from "react-icons/tb";
import { useLocation, useParams } from "react-router-dom";
import { useEffect } from "react";
import { DataContext } from "../../context/DataState";
import noAgenda from '../Assets/No Results@3x.png'
const Agenda = () => {
  const [isDay, setIsDay] = useState(false);
  const { getSessionApi, getAgendaSessionState, globalLoader } = useContext(DataContext);

  const eventDetail = (JSON.parse(localStorage.getItem("eventDetail")));
  const startDate = new Date(eventDetail?.eventStartDate);
  const endDate = new Date(eventDetail?.eventEndDate);
  const arrDays = [];
  while (startDate <= endDate) {
    arrDays.push(startDate.toISOString());
    startDate.setDate(startDate.getDate() + 1);
  };
  const [getDateData, setGetDateData] = useState(eventDetail?.eventStartDate);

  const getTimeDifference = (startTime, endTime) => {
    const timeDifference = (new Date(endTime) - new Date(startTime)) / (1000 * 60); // Time difference in minutes

    const hours = Math.floor(timeDifference / 60);
    const minutes = timeDifference % 60;

    const formattedTimeDifference = `${hours} hour${hours !== 1 ? "s" : ""} ${minutes} minute${minutes !== 1 ? "s" : ""}`;

    return (
      <>{formattedTimeDifference}</>
    )
  }

  // const getTime = new Date(getAgendaSessionState.startTime);
  // console.log(getTime,"Time")

  useEffect(() => {
    getSessionApi(eventDetail._id, getDateData)
  }, [getDateData])

  return (
    <div className="px-4">
      <div className="flex justify-between items-center">
        <ul className="flex gap-x-2 text-center whitespace-nowrap w-[90%] overflow-x-scroll">
          {
            arrDays && arrDays?.map((item, index) => (
              <li className="w-[145px]">
                <div className={`p-2 ${item === getDateData ? "border-b-2 border-b-theme-color": "" } cursor-pointer`} onClick={(() => { setGetDateData(item) })}>
                  <p className="3xl:text-[16px]">DAY {index + 1}</p>
                  <span className="3xl:text-[11px]">{item.slice(0, -14)}</span>
                </div>
              </li>
            ))
          }
        </ul>
        <div className="filter flex flex-col items-center">
          <BiMinus className="font-normal text-[14px]" />
          <span className="3xl:text-[14px] 4xl:text-[16px] text-[12px]">Filter</span>
        </div>
      </div>

      {globalLoader ?
        <div className="relative w-full h-[70vh] ">
          <div id="container" className="absolute top-1/2 left-1/2 -translate-x-1/2 ">
            <div className="flex gap-2 items-center">
              <div id="ball-1" className="circle"></div>
              <div id="ball-2" className="circle"></div>
              <div id="ball-3" className="circle"></div>
            </div>
          </div>
        </div>
        :
        <div className="grid text-center [&>*:nth-child(odd)]:bg-[#FAFBFB]">
          {
            getAgendaSessionState?.length !== 0 ?
              getAgendaSessionState && getAgendaSessionState?.map((item) => (
                <div className="grid border-b border-b-[#00000025] py-3 grid-cols-[142px_auto] place-items-start">
                  <div className="flex w-full flex-col gap-1 justify-center items-center">
                    {/* <p className="text-[#202223] 3xl:text-[18px] font-normal">{item.startTime.split('T')[1].slice(0, 5)}</p> */}
                    <p className="text-[#202223] 3xl:text-[18px] font-normal">{`${(item.startTime).slice(11,-8)} to ${(item.endTime).slice(11,-8)}`}</p>
                    <span className="text-black 3xl:text-[14px] text-[10px]">{item.Location}</span>
                  </div>
                  <div className="flex flex-col gap-1 px-4">
                    <p className="text-[#202223] 3xl:text-[18px] font-normal">
                      {item.agenda_name}
                    </p>
                    <div className="flex gap-1 items-center">
                      <RiTimer2Fill className="text-[#BDBDBD]" />
                      <span className="text-black 3xl:text-[14px] text-[10px]">{getTimeDifference(item.startTime, item.endTime)}</span>
                    </div>
                  </div>
                </div>
              ))
              : <div className="flex justify-center items-start w-full h-[70vh]">
                <img src={noAgenda} alt="" className="w-[300px] mt-24" />
              </div>
          }

        </div>}
    </div>
  );
};

export default Agenda;
