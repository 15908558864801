import React, { useContext, useEffect, useState } from "react";
import { FiTrash,FiEdit2 } from 'react-icons/fi'
import { Link } from "react-router-dom";
import Modal from "./EventModal";
import { DataContext } from "../../context/DataState";
import { AiFillCaretDown } from "react-icons/ai";
import CreateEvent from "../Event/CreateEvent"
import { useNavigate } from "react-router-dom";
import GlobalLoader from "../GlobalLoader/GlobalLeader";

function EventList() {
  const [openModal, setOpenModal] = useState(false);
  const [eventCategory, setEventCategory] = useState("")
  const { EventAllListApi, resultEventListApi, EventTrackListApi, resultTrackEventList,
    setSoloEventDetails, globalLoader } = useContext(DataContext);
  const navigate = useNavigate();


  const handleNavigate = (item) => {
    localStorage.setItem(
      "eventDetail",
      JSON.stringify(item)
    );
    navigate(`/event-details`)
  };

  useEffect(() => {
    setEventCategory("")
    EventAllListApi()
  }, []);


  return (
    <>
      <div className="w-4/5 flex bg-white">
        {
          resultEventListApi?.length !== 0 ?
            <div className="w-full px-5 py-2">
              <div className="flex justify-between items-center py-2">
                <h1 className="font-poppins font-semibold text-xl leading-10 text-title-color">Event List</h1>
                <button type="button" className="bg-[#FF8531] font-semibold text-white text-sm p-2 rounded" onClick={() => navigate("/register-event")}>Add New Event</button>
              </div>
              <div>
                <div className="mt-8">
                  <ul className=" flex items-center">
                    <li className={`${eventCategory === "" ? "text-[#FF8531] bg-theme-light-color" : <></>} font-semibold text-[12px] text-[#202223] px-3 py-2 cursor-pointer`} onClick={() => { setEventCategory(""); EventAllListApi() }}>All</li>
                    <li className={`${eventCategory === "ongoing" ? "text-[#FF8531] bg-theme-light-color" : <></>} font-semibold text-[12px] text-[#202223] px-3 py-2 cursor-pointer`} onClick={() => {
                      EventTrackListApi("ongoing");
                      setEventCategory("ongoing");
                    }}>On-going</li>
                    <li className={`${eventCategory === "upcoming" ? "text-[#FF8531] bg-theme-light-color" : <></>} font-semibold text-[12px] text-[#202223] px-3 py-2 cursor-pointer`} onClick={() => {
                      EventTrackListApi("upcoming");
                      setEventCategory("upcoming");
                    }}>Up-coming</li>
                    <li className={`${eventCategory === "past" ? "text-[#FF8531] bg-theme-light-color" : <></>} font-semibold text-[12px] text-[#202223]  px-3 py-2 cursor-pointer`} onClick={() => { EventTrackListApi("past"); setEventCategory("past") }}>Past</li>
                  </ul>

                </div>

              </div>
              {
                globalLoader ? <div className="relative w-full h-[70vh] ">
                  <div id="container" className="absolute top-1/2 left-1/2 ">
                    <div className="flex gap-2 items-center">
                      <div id="ball-1" className="circle"></div>
                      <div id="ball-2" className="circle"></div>
                      <div id="ball-3" className="circle"></div>
                    </div>
                  </div>
                </div> : <div className="w-full bg-[#FAFBFB] rounded-lg shadow-md mt-4">
                  <table className="border border-separate border-white w-full">
                    <thead className="cursor-pointer"  >
                      <tr className="text-xs text-[#6D7175] text-left font-normal h-[32px] whitespace-nowrap even:bg-[#FAFBFB] ">
                        
                        <th className="border-b border-white  px-4 py-2">Event Name</th>
                        <th className="border-b border-white px-4 py-2">Organiser</th>
                        <th className="border-b border-white px-4 py-2">Email address</th>
                        <th className="border-b border-white px-4 py-2">Business Name</th>
                        <th className="border-b border-white px-4 py-2">Start date</th>
                        <th className="border-b border-white px-4 py-2">End date</th>
                        {/* <th className="border-b border-white px-4 py-2 text-center">Action</th> */}
                      </tr>
                    </thead>
                    <tbody className="[&>*:nth-child(even)]:bg-[#FAFBFB]">
                      {
                        eventCategory === '' ? resultEventListApi?.map((item) => (
                          <tr className="bg-white group font-normal text-sm  px-4 py-2 text-left cursor-pointer hover:shadow-tableRowsShadow" onClick={() => { handleNavigate(item) }}>
                            {/* <td className="border-b border-white px-4 "><input type="checkbox" name="eventCheckbox" id="eventCheckbox" className="border-2 border-[#8C9196]" /></td> */}
                            <td className="border-b border-white px-4 py-2 text-[#202223]">{item.eventName}</td>
                            <td className="border-b border-white px-4 py-2 text-[#202223]">{item.OrganizerName}</td>
                            <td className="border-b border-white px-4 py-2 text-[#202223]">{item.email}</td>
                            <td className="border-b border-white px-4 py-2 text-[#202223]">{item.business}</td>
                            <td className="border-b border-white px-4 py-2 text-[#202223]">{item?.eventStartDate?.slice(0, -14)}</td>
                            <td className="border-b border-white px-4 py-2 text-[#202223]">{item?.eventEndDate?.slice(0, -14)} </td>
                            {/* <td className=''> 
                              <div className="flex items-center  justify-center  gap-2 text-black "> 
                                <FiEdit2 className='hover:text-theme-color hover:bg-theme-light-color hover:rounded-full px-[6px] py-0 text-[28px]'/> 
                                <FiTrash className='hover:text-theme-color hover:bg-theme-light-color hover:rounded-full px-[6px] py-0 text-[28px]'/> 
                              </div>
                            </td> */}
                          </tr>
                        ))
                          :
                          resultTrackEventList?.length !== 0 ?
                            resultTrackEventList?.map((item) => (
                              <tr className="bg-white group font-normal text-sm text-left cursor-pointer hover:shadow-tableRowsShadow" onClick={() => { handleNavigate(item) }}>
                                
                                <td className="border-b border-white px-4 py-2 text-[#202223]">{item.eventName}</td>
                                <td className="border-b border-white px-4 py-2 text-[#202223]">{item.OrganizerName}</td>
                                <td className="border-b border-white px-4 py-2 text-[#202223]">{item.email}</td>
                                <td className="border-b border-white px-4 py-2 text-[#202223]">{item.business}</td>
                                <td className="border-b border-white px-4 py-2 text-[#202223]">{item?.eventStartDate?.slice(0, -14)}</td>
                                <td className="border-b border-white px-4 py-2 text-[#202223]">{item?.eventEndDate?.slice(0, -14)} </td>
                                {/* <td> 
                                  <div className="flex cursor-pointer items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity text-[18px] gap-4 text-theme-color"> 
                                    <FiEdit2 className="hover:text-theme-color hover:bg-theme-light-color hover:rounded-full px-[6px] py-0 text-[28px]"/> 
                                    <FiTrash className="hover:text-theme-color hover:bg-theme-light-color hover:rounded-full px-[6px] py-0 text-[28px]"/> 
                                  </div>
                                </td> */}
                              </tr>
                            )) : <div>No {eventCategory} Event </div>

                      }
                    </tbody>
                  </table>
                </div>
              }

            </div>
            : <>
              <CreateEvent />
            </>
        }

      </div>
    </>
  );
}

export default EventList;