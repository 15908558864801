import React, { useState } from "react";
import { Link } from "react-router-dom";
import SignUpFav from "../Assets/favicon.png";
import {
  FaEnvelope,
  FaLock,
  FaRegEyeSlash,
  FaRegEye,
  FaUserAlt,
} from "react-icons/fa";

function SignUp() {
  const [password, setPassword] = useState(false);
  const [ConfirmPassword, setConfirmPassword] = useState(false);

  return (
    <>
      <div className="w-full h-screen flex items-center justify-center flex-col">
        <div className="w-[40%] xl:w-[90%] bg-white shadow hover:shadow-lg rounded-md">
          <div className="bg-theme-color py-4 rounded-t-md">
            <h1 className="text-center text-[36px] 2xl:text-[30px] font-poppins font-bold text-white mb-2">
              Register
            </h1>
            <p className="text-center text-white text-xl 2xl:text-base mb-3 font-poppins">
              Enter your data for registration on Event Web
            </p>
            <img
              src={SignUpFav}
              className="w-12 h-12 bg-white rounded-[50%] p-2 m-auto -mb-8 shadow-[0_3px_5px_#5690F6]" alt="favicon"
            ></img>
          </div>
          <div className="p-14 md:p-8">
            <form>
              <div className="flex gap-x-2">
                <label className="relative block mb-4 w-full">
                  <span className="absolute inset-y-0 left-0 flex items-center pl-2">
                    <FaUserAlt className="h-4 w-4 fill-theme-color" />
                  </span>
                  <input
                    type="text"
                    className="mt-1 block w-full px-3 pl-8 py-3 bg-white border border-theme-color rounded-md text-sm shadow-sm placeholder-slate-400 focus:outline-none focus:border-[#5690F6] focus:ring-1 focus:ring-[#5690F6] invalid:border-red-500 invalid:text-red-600 focus:invalid:border-red-500 focus:invalid:ring-red-500 font-poppins"
                    placeholder="First Name"
                  />
                </label>
                <label className="relative block mb-4 w-full">
                  <span className="absolute inset-y-0 left-0 flex items-center pl-2">
                    <FaUserAlt className="h-4 w-4 fill-theme-color" />
                  </span>
                  <input
                    type="text"
                    className="mt-1 block w-full px-3 pl-8 py-3 bg-white border border-theme-color rounded-md text-sm shadow-sm placeholder-slate-400 focus:outline-none focus:border-[#5690F6] focus:ring-1 focus:ring-[#5690F6] invalid:border-red-500 invalid:text-red-600 focus:invalid:border-red-500 focus:invalid:ring-red-500 font-poppins"
                    placeholder="Last Name"
                  />
                </label>
              </div>

              <label className="relative block mb-4 w-full">
                <span className="absolute inset-y-0 left-0 flex items-center pl-2">
                  <FaEnvelope className="h-4 w-4 fill-theme-color" />
                </span>
                <input
                  type="email"
                  className="mt-1 block w-full px-3 pl-8 py-3 bg-white border border-theme-color rounded-md text-sm shadow-sm placeholder-slate-400 focus:outline-none focus:border-[#5690F6] focus:ring-1 focus:ring-[#5690F6] invalid:border-red-500 invalid:text-red-600 focus:invalid:border-red-500 focus:invalid:ring-red-500 font-poppins"
                  placeholder="Enter your email"
                />
              </label>
              <div className="flex gap-x-2">
                <label className="relative block mb-4 w-full">
                  <span className="absolute inset-y-0 left-0 flex items-center pl-2">
                    <FaLock className="h-4 w-4 fill-theme-color" />
                  </span>
                  <input
                    type={password === true ? "text" : "password"}
                    className="mt-1 block w-full pr-8 pl-8 py-3 bg-white border border-theme-color rounded-md text-sm shadow-sm placeholder-slate-400 focus:outline-none focus:border-[#5690F6] focus:ring-1 focus:ring-[#5690F6] invalid:border-red-500 invalid:text-red-600 focus:invalid:border-red-500 focus:invalid:ring-red-500 font-poppins"
                    placeholder="Password"
                  />
                  <span
                    className="absolute inset-y-0 right-0 flex items-center pr-2 cursor-pointer"
                    onClick={() => setPassword(!password)}
                  >
                    {password === true ? (
                      <FaRegEye className="h-5 w-5 fill-theme-color" />
                    ) : (
                      <FaRegEyeSlash className="h-5 w-5 fill-theme-color" />
                    )}
                  </span>
                </label>
                <label className="relative block mb-4 w-full">
                  <span className="absolute inset-y-0 left-0 flex items-center pl-2">
                    <FaLock className="h-4 w-4 fill-theme-color" />
                  </span>
                  <input
                    type={ConfirmPassword === true ? "text" : "password"}
                    className="mt-1 block w-full pr-8 pl-8 py-3 bg-white border border-theme-color rounded-md text-sm shadow-sm placeholder-slate-400 focus:outline-none focus:border-[#5690F6] focus:ring-1 focus:ring-[#5690F6] invalid:border-red-500 invalid:text-red-600 focus:invalid:border-red-500 focus:invalid:ring-red-500 font-poppins"
                    placeholder="Confirm Password"
                  />
                  <span
                    className="absolute inset-y-0 right-0 flex items-center pr-2 cursor-pointer"
                    onClick={() => setConfirmPassword(!ConfirmPassword)}
                  >
                    {ConfirmPassword === true ? (
                      <FaRegEye className="h-5 w-5 fill-theme-color" />
                    ) : (
                      <FaRegEyeSlash className="h-5 w-5 fill-theme-color" />
                    )}
                  </span>
                </label>
              </div>
              <label className="relative block mb-4 w-full">
                  <input
                    type="file"
                    className="mt-1 block w-full px-2 py-3 bg-white border border-theme-color rounded-md text-sm shadow-sm placeholder-slate-400 focus:outline-none focus:border-[#5690F6] focus:ring-1 focus:ring-[#5690F6] invalid:border-red-500 invalid:text-red-600 focus:invalid:border-red-500 focus:invalid:ring-red-500 font-poppins text-sm text-slate-500
                    file:mr-4 file:py-2 file:px-4
                    file:rounded-full file:border-0
                    file:text-sm file:font-semibold
                    file:bg-sky-50 file:text-sky-700
                    hover:file:bg-sky-100"
                  />
                </label>
              <div className="text-xl 2xl:text-sm">
                <div className="font-poppins">
                  <input type="checkbox" /> By creating an account, you agree to
                  our <Link to="/terms-condition" className="text-theme-color font-poppins font-medium underline" rel="noreferrer">Terms </Link>
                  and have read and acknowledge the
                  <Link to="/privacy-policy" className="text-theme-color font-poppins font-medium underline" rel="noreferrer"> Privacy Policy</Link>.
                </div>
              </div>
              <div className="flex justify-center mt-4 mb-4">
                <button
                  type="submit"
                  className="bg-theme-color text-white p-2 w-80 rounded-lg font-poppins font-bold"
                >
                  SignUp
                </button>
              </div>
              <div className="text-center">
                <p className="text-xl 2xl:text-sm font-poppins">
                  already have an account?
                  <Link
                    to="/"
                    className="text-theme-color font-medium underline"
                    rel="noreferrer"
                  >
                    Login
                  </Link>
                </p>
              </div>
            </form>
          </div>
        </div>
        <span className="font-poppins mt-4">
          © 2023 Event Web by <Link to="https://www.parangat.com/" className="text-theme-color underline" target="_blank" rel="noreferrer noopener">Parangat Technologies </Link>
        </span>
      </div>
    </>
  );
}

export default SignUp;
