import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./App.css";
import Login from "./components/Login/Login";
import SignUp from "./components/SignUp/SignUp";
import ForgotPassword from "./components/ForgotPassword/ForgotPassword";
import ResetPassword from "./components/ResetPassword/ResetPassword";
import TermsCondition from "./components/TermsAndCondition/TermsCondition";
import PrivacyPolicy from "./components/PrivacyPolicy/PrivacyPolicy";
import Dashboard from "./components/Dashboard/Dashboard";
import EventList from "./components/Event/EventList";
import Speaker from "./components/Speaker/Speaker";
import Sponsors from "./components/Sponsors/Sponsors";
import Notification from "./components/Notification/Notification";
import Chat from "./components/Chat/Chat";
import DataProvider from "./context/DataState";
import CreateEvent from "./components/Event/CreateEvent";
import RegisterEvent from "./components/Event/RegisterEvent";
import EventDetails from "./components/Event/EventDetails";
import Admin from "./components/Admin/Admin";
import Sidebar from "./components/Sidebar/Sidebar";

function App() {
  return (
    <div className="App">
      <Router>
        <DataProvider>
          <div className="flex w-full">
            <Sidebar/>
          <Routes>
            <Route exact path="/" element={<Login />} />
            <Route exact path="/signup" element={<SignUp />} />
            <Route exact path="/forgot-password" element={<ForgotPassword />} />
            <Route exact path="/reset-password" element={<ResetPassword />} />
            <Route exact path="/terms-condition" element={<TermsCondition />} />
            <Route exact path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route exact path="/dashboard" element={<Dashboard />} />
            <Route exact path="/event" element={<EventList />} />
            {/* <Route exact path="/speaker" element={<Speaker />} />
            <Route exact path="/sponsors" element={<Sponsors />} /> */}
            <Route exact path="/notification" element={<Notification />} />
            <Route exact path="/chat" element={<Chat />} />
            <Route exact path="/create-event" element={<CreateEvent/>} />
            <Route exact path="/register-event" element={<RegisterEvent/>} />
            <Route exact path="/event-details" element={<EventDetails />} />
            <Route exact path="/admin" element={<Admin/>} />
          </Routes>
          </div>
        </DataProvider>
      </Router>
    </div>
  );
}

export default App;
