import React from "react";

const GlobalLoader = () => {
  return (
    <>
    <div className="relative w-full h-screen ">
  <div id="container" className="absolute top-1/2 left-1/2 ">
    <div className="flex gap-2 items-center">
  <div id="ball-1" className="circle"></div>
  <div id="ball-2" className="circle"></div>
  <div id="ball-3" className="circle"></div>
  </div>
</div>
</div>
    </>
  )
}
export default GlobalLoader;