import React, { useContext, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import logo from "../Assets/logo.png";
import { FaEnvelope, FaLock, FaRegEyeSlash, FaRegEye } from "react-icons/fa";
import { DataContext } from "../../context/DataState";
import { ToastContainer } from "react-toastify";

const Login = () => {
  const navigate = useNavigate();
  const { LoginApi, resultLoginApi, buttonLoader, setButtonLoader, setResultLoginApi } = useContext(DataContext);
  const [password, setPassword] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);

  const [state, setState] = useState({
    email: "",
    password: "",
  });

  const validEmail = new RegExp(
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );

  const validateEmail = () => {
    if (!validEmail.test(state.email)) {
      setEmailError(true);
    } else {
      setEmailError(false);
      setButtonLoader(false);
    }
  }

  const validatePassword = () => {
    if (state.password === "") {
      setPasswordError(true);
      setResultLoginApi("");
    } else {
      setPasswordError(false);
      setButtonLoader(false);
      setResultLoginApi("");
    }
  }

  const buttonDisabled = (state.email !== '' && state.password !== '');

  const handleLogin = () => {
    LoginApi(state);
    setButtonLoader(true);
  };


  return (
    <>
      <ToastContainer/>
      <div className="w-full h-screen flex items-center justify-center flex-col">
        <div className="w-[33%] xl:w-[90%] bg-white shadow hover:shadow-lg rounded-md">
          <div className="bg-orange-300 py-4 rounded-t-md">
            <h1 className="text-center text-[36px] 2xl:text-[30px] font-poppins font-bold text-white mb-2">Welcome Back</h1>
            <p className="text-center text-white text-xl 2xl:text-base mb-3 font-poppins">
              Sign in to continue to Event Plus SuperAdmin
            </p>
            <img src={logo} className="w-16 h-16 bg-white rounded-[50%]  m-auto -mb-10 shadow-[#272525ce] shadow-sm" alt="favicon"></img>
          </div>
          <div className="p-14 md:p-8">
            <form>
              <label className="relative block">
                <span className="absolute inset-y-0 left-0 flex items-center pl-2">
                  <FaEnvelope className="h-4 w-4 fill-theme-color" />
                </span>
                <input
                  type="email"
                  className="mt-1 block w-full px-3 pl-8 py-3 bg-white border  rounded-md text-sm shadow-sm placeholder-slate-400   font-poppins
                   focus-visible:border-inputBorder focus-visible:outline-none   focus-visible:shadow-focusshadow focus-visible:border-1"
                  placeholder="Enter your email"
                  name="email"
                  value={state.email}
                  onChange={(e) => {
                    setState({ ...state, email: e.target.value });
                  }}
                  onKeyUp={() => validateEmail()}
                />
              </label>
              {emailError && (
                <small className="text-left text-red-600">Enter Valid Email</small>
              )}
              <label className="relative block mt-6">
                <span className="absolute inset-y-0 left-0 flex items-center pl-2">
                  <FaLock className="h-4 w-4 fill-theme-color" />
                </span>
                <input
                  type={password === true ? "text" : "password"}
                  className="mt-1 block w-full pr-8 pl-8 py-3 bg-white border  rounded-md text-sm shadow-sm placeholder-slate-400 focus:outline-none 
                  focus-visible:border-inputBorder focus-visible:outline-none   focus-visible:shadow-focusshadow focus-visible:border-1 font-poppins"
                  placeholder="Password"
                  name="password"
                  value={state.password}
                  onChange={(e) => {
                    setState({ ...state, password: e.target.value });
                  }}
                  onKeyUp={() => validatePassword()}
                />
                <span
                  className="absolute inset-y-0 right-0 flex items-center pr-2 cursor-pointer"
                  onClick={() => setPassword(!password)}
                >
                  {password === true ? (
                    <FaRegEye className="h-5 w-5 fill-theme-color" />
                  ) : (
                    <FaRegEyeSlash className="h-5 w-5 fill-theme-color" />
                  )}
                </span>
              </label>
              {passwordError && (
                <small className="text-left text-red-600">Enter Password</small>
              )}
              <small className="text-red-500">{resultLoginApi}</small>


              <div className="flex justify-center mt-12 mb-7">
                <button
                  onClick={() => handleLogin()}
                  type="button" disabled={!buttonDisabled} className="bg-theme-color disabled:opacity-40 flex items-center gap-2 text-white transition ease-in-out delay-150 duration-300 p-[.5rem_7.5rem] rounded-lg font-poppins font-bold" >
                  <span className={` ${buttonLoader ? "border-t-transparent inline-block border-2 border-white animate-spin rounded-full w-5 h-5" : <></>} `}></span> Login
                </button>
              </div>
              {/* <div className="text-center">
                <p className="text-xl 2xl:text-sm font-poppins">Don't have an account? <Link to="/signup" className="text-theme-color font-medium underline" rel="noreferrer">Register</Link></p>
              </div> */}
            </form>
          </div>
        </div>
        {/* <span className="font-poppins mt-4">© 2023 Event Web by <Link to="https://www.parangat.com/" className="text-theme-color underline" target="_blank" rel="noreferrer noopener">Parangat Technologies</Link></span> */}
      </div>
    </>
  );
}

export default Login;
