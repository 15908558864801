import React from "react";
import { useContext } from "react";
import { DataContext } from "../../context/DataState";
import { useEffect } from "react";
// import noAgenda from '../Assets/No Results@3x.png';


const EventSponsors = () => {
  const { eventSponserApi, eventSponsersState } = useContext(DataContext);
  const eventDetail = (JSON.parse(localStorage.getItem("eventDetail")));

  // const { id } = eventDetail._id;

  useEffect(() => {
    eventSponserApi(eventDetail?._id)
  }, [])


  return (
    // <>
    // <div className="flex justify-center items-start w-full h-[70vh]">
    //         <img src={noAgenda} alt="" className="w-[300px] mt-24" />
    //       </div>
    // </>

    <>
    {
      eventSponsersState?.map((item) => (
      <div className="platinum flex flex-col gap-4">
        <div className="flex items-center gap-4 pt-4">
          <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12.8615 14.6324C13.3681 13.7231 13.6215 13.2684 14.0001 13.2684C14.3788 13.2684 14.6321 13.7218 15.1388 14.6324L15.2695 14.8671C15.4135 15.1258 15.4855 15.2538 15.5975 15.3391C15.7108 15.4244 15.8508 15.4564 16.1308 15.5191L16.3841 15.5778C17.3681 15.8004 17.8601 15.9111 17.9775 16.2871C18.0948 16.6644 17.7588 17.0564 17.0881 17.8404L16.9148 18.0431C16.7241 18.2658 16.6281 18.3764 16.5855 18.5151C16.5428 18.6538 16.5575 18.8018 16.5855 19.0991L16.6121 19.3698C16.7135 20.4164 16.7641 20.9404 16.4588 21.1724C16.1521 21.4044 15.6908 21.1924 14.7695 20.7684L14.5321 20.6591C14.2695 20.5391 14.1388 20.4778 14.0001 20.4778C13.8615 20.4778 13.7308 20.5391 13.4681 20.6591L13.2308 20.7684C12.3095 21.1938 11.8481 21.4044 11.5415 21.1724C11.2348 20.9404 11.2868 20.4164 11.3881 19.3698L11.4148 19.0991C11.4428 18.8018 11.4575 18.6538 11.4148 18.5151C11.3721 18.3778 11.2761 18.2658 11.0855 18.0431L10.9121 17.8404C10.2415 17.0564 9.90546 16.6644 10.0228 16.2871C10.1401 15.9111 10.6321 15.8004 11.6161 15.5778L11.8695 15.5191C12.1495 15.4564 12.2895 15.4258 12.4028 15.3391C12.5148 15.2538 12.5868 15.1258 12.7308 14.8671L12.8615 14.6324Z" stroke="#898A8D" stroke-width="1.5" />
            <path d="M27.1174 12.7697C27.3574 10.2204 27.4774 8.94441 27.0414 8.41775C26.9315 8.28043 26.7953 8.16643 26.6407 8.08241C26.4862 7.99839 26.3164 7.94603 26.1414 7.92841C25.5068 7.87241 24.7108 8.77908 23.1174 10.5924C22.2934 11.5311 21.8814 11.9991 21.4228 12.0724C21.1666 12.1123 20.9043 12.0703 20.6734 11.9524C20.2481 11.7391 19.9668 11.1591 19.4001 10.0004L16.4174 3.88841C15.3481 1.69775 14.8134 0.601746 14.0001 0.601746C13.1868 0.601746 12.6521 1.69775 11.5828 3.88841L8.60009 10.0004C8.03476 11.1604 7.75209 11.7391 7.32676 11.9524C7.0958 12.0702 6.83361 12.1122 6.57743 12.0724C6.11876 11.9991 5.70676 11.5311 4.88276 10.5924C3.28943 8.77908 2.49343 7.87241 1.85876 7.92841C1.68374 7.94603 1.514 7.99839 1.35946 8.08241C1.20492 8.16643 1.0687 8.28043 0.958759 8.41775C0.522759 8.94441 0.642759 10.2204 0.882759 12.7697L1.18809 16.0177C1.69209 21.3671 1.94276 24.0431 3.52143 25.6551C5.09609 27.2684 7.45876 27.2684 12.1868 27.2684H15.8121C20.5388 27.2684 22.9014 27.2684 24.4788 25.6551C25.6974 24.4084 26.1241 22.5284 26.4881 19.2684" fill={item?.level_color} />
            <defs>
              <linearGradient id="paint0_linear_501_3972" x1="0.666992" y1="13.9351" x2="27.3332" y2="13.9351" gradientUnits="userSpaceOnUse">
                <stop stop-color="#ACB6E5" />
                <stop offset="1" stop-color="#86FDE8" />
              </linearGradient>
            </defs>
          </svg>
          <h1 className="3xl:text-[15px] font-medium">{item?.level_name} Sponsors</h1>
        </div>
        <div className="sponsors-list grid gap-6 grid-cols-5 py-4">
          {
            item.Sponsers && item.Sponsers?.map((items) => (
              <div className="card bg-white flex justify-center items-center p-4">
                <img src={items?.bannerimage} className="object-contain" alt="sponsor-simage" />
              </div>
            ))
          }
        </div>
      </div>
      ))
    }
    </>

    // <>
    // {
    //   eventSponsersState?.Platinum.length || eventSponsersState?.Gold.length || eventSponsersState?.Silver.length || eventSponsersState?.Bronze.length !== 0 ? 
    //   <div>
    //   {
    //     eventSponsersState?.Platinum.length !== 0 ?
    //       <div className="platinum flex flex-col gap-4">
    //         <div className="flex items-center gap-4 pt-4">
    //           <img src={plat} alt="" />
    //           <h1 className="3xl:text-[15px] font-medium">Platinum Sponsors</h1>
    //         </div>
    //         <div className="sponsors-list grid gap-6 grid-cols-5 py-4">
    //           {
    //             eventSponsersState && eventSponsersState?.Platinum?.map((item) => (
    //               <div className="card bg-white flex justify-center items-center">
    //                 <img src={sponsor10} alt="" />
    //               </div>
    //             ))
    //           }
    //         </div>
    //       </div> : <></>
    //   }

    //   {
    //     eventSponsersState?.Gold.length !== 0 ?
    //       <div className="platinum flex flex-col gap-4">
    //         <div className="flex items-center gap-4 pt-4">
    //           <img src={gold} alt="" />
    //           <h1 className="3xl:text-[15px] font-medium">Gold Sponsors</h1>
    //         </div>
    //         <div className="sponsors-list grid gap-6 grid-cols-5 py-4">
    //           {
    //             eventSponsersState && eventSponsersState?.Gold?.map((item) => (
    //               <div className="card bg-white flex justify-center items-center">
    //                 <img src={sponsor10} alt="" />
    //               </div>
    //             ))
    //           }
    //         </div>
    //       </div> : <></>
    //   }


    //   {
    //     eventSponsersState?.Silver.length !== 0 ?
    //       <div className="platinum flex flex-col gap-4">
    //         <div className="flex items-center gap-4 pt-4">
    //           <img src={gold} alt="" />
    //           <h1 className="3xl:text-[15px] font-medium">Silver Sponsors</h1>
    //         </div>
    //         <div className="sponsors-list grid gap-6 grid-cols-5 py-4">
    //           {
    //             eventSponsersState && eventSponsersState?.Silver?.map((item) => (
    //               <div className="card bg-white flex justify-center items-center">
    //                 <img src={sponsor10} alt="" />
    //               </div>
    //             ))
    //           }
    //         </div>
    //       </div> : <></>
    //   }

    //   {
    //     eventSponsersState?.Bronze.length !== 0 ?
    //       <div className="platinum flex flex-col gap-4">
    //         <div className="flex items-center gap-4 pt-4">
    //           <img src={gold} alt="" />
    //           <h1 className="3xl:text-[15px] font-medium">Bronze Sponsors</h1>
    //         </div>
    //         <div className="sponsors-list grid gap-6 grid-cols-5 py-4">
    //           {
    //             eventSponsersState && eventSponsersState?.Bronze?.map((item) => (
    //               <div className="card bg-white flex justify-center items-center">
    //                 <img src={sponsor10} alt="" />
    //               </div>
    //             ))
    //           }
    //         </div>
    //       </div> : <></>
    //   }
    // </div> : <>
    // No Sponsores Yet!!!
    // </>
    // }
    // </>
  );
};

export default EventSponsors;
