import React from "react";
import createEventImage from "../Assets/createEvent.svg";
import { useNavigate } from "react-router-dom";
import RegisterEvent from "../Event/RegisterEvent"

const CreateEvent = () => {
  const navigate=useNavigate();
  return (
    <>
      <div className="w-full flex bg-[#F6F6F7]">
        <div className="w-full h-screen">
          <h1 className="font-semibold text-[20px] ml-8 my-7">Events</h1>
          <div className="mx-8 bg-white  flex flex-col items-center justify-center h-[65vh] ">
            <img
              src={createEventImage}
              alt="create-Event"
              className="w-[112px] h-[152px]"
            />
            <p className="font-semibold text-[20px] leading-9">
              This is where you’ll manage your Event
            </p>
            <p className="font-normal text-[14px] text-[#6D7175] leading-8">
              You can create a new Event
            </p>
            <button className="bg-[#FF8531] font-semibold text-[14px] leading-5 py-2 px-12 rounded-md text-white shadow-eventBtn"
            onClick={()=>navigate('/register-event')}>
              Create Event
            </button>
          </div>
        </div>
      </div>
    </>
  );
};
export default CreateEvent;
