import React, { useContext, useEffect } from "react";
import {FaArrowCircleLeft,FaMicrophone, FaSkype, FaAddressBook,FaThLarge,  FaRegCalendarAlt, FaUser} from "react-icons/fa";
import {TbTargetArrow} from "react-icons/tb"
import {FiLogOut} from "react-icons/fi"
import { Link, useLocation } from "react-router-dom";
import Logo from"../Assets/event logo.png";
import { DataContext } from "../../context/DataState";
import LogoutModal from "../LogoutModal/LogoutModal";


function Sidebar() {
  const { logout, setLogout,checkLogin,setCheckLogin} = useContext(DataContext);
  const location = useLocation()
  useEffect(()=>{}, [location]);
  return(
    <>
    {logout ? <LogoutModal /> : ""}
      {checkLogin ? (
      <div className="w-1/5 shrink-0 bg-theme-bg h-screen px-4 border-r">
        <div>
          <img className="w-[180px] py-5" src={Logo} alt="" />
        </div>
        {/* <div className="flex items-center justify-center  gap-4 pl- pr-5 pt-5 pb-6">
          <img className="w-[25px] " src={Sidebar1} alt="sidebar"></img>
          <h2 className="text-black font-bold font-poppins lg:text-lg 2xl:text-2xl text-xl ">Event Web</h2>
          <span ><FaArrowCircleLeft/></span>
        </div> */}
        <ul className="  flex flex-col  gap-[4px]">
          
          <li id="dashboard"  className={`flex items-center hover:text-theme-color  hover:bg-theme-light-color justify-between transition-all rounded-xl  ${location.pathname === "/dashboard" ? "bg-theme-color text-white active" : "text-black"} `}>
            <Link className="w-full p-3" to="/dashboard">
              <div className="flex items-center text-sm">
                <FaThLarge />
                <h2 className=" text-sm font-normal pl-6 ">Dashboard</h2>
              </div>
            </Link>
          </li>
          <li className={`flex items-center  hover:text-theme-color  hover:bg-theme-light-color justify-between transition-all rounded-xl  ${location.pathname === "/event" || location.pathname === "/event-details" ? "bg-theme-color text-white active" : "text-black"}   `}>
            <Link className="w-full p-3" to="/event">
              <div className="flex items-center  text-sm">
                <TbTargetArrow size={19}/>
                <h2 className=" text-sm font-normal pl-6">Event</h2>
              </div>
            </Link>
          </li>
          {/* <li className={`flex items-center  hover:text-theme-color  hover:bg-theme-light-color justify-between transition-all rounded-xl  ${location.pathname === "/speaker" ? "bg-theme-color text-white active" : "text-black"}   `}>
            <Link to="/speaker">
              <div className="flex items-center  text-sm">
                < FaMicrophone/>
                <h2 className=" text-md font-normal pl-6">Speaker</h2>
              </div>
            </Link>
          </li> */}
          {/* <li className={`flex items-center  hover:text-theme-color  hover:bg-theme-light-color justify-between transition-all rounded-xl  ${location.pathname === "/sponsors" ? "bg-theme-color text-white active" : "text-black"}   `}>
            <Link to="/sponsors">
              <div className="flex items-center  text-sm">
                <FaAddressBook />
                <h2 className=" text-md font-normal pl-6">Sponsors</h2>
              </div>
            </Link>
          </li> */}
          <li className={`flex items-center  hover:text-theme-color  hover:bg-theme-light-color justify-between transition-all rounded-xl  ${location.pathname === "/admin" ? "bg-theme-color text-white active" : "text-black"}   `}>
            <Link className="w-full p-3" to="/admin">
              <div className="flex items-center  text-sm">
                <FaUser size={17}/>
                <h2 className=" text-sm font-normal pl-6">Admin</h2>
              </div>
            </Link>
          </li>

          <li className={`flex items-center  hover:text-theme-color mb-12 hover:bg-theme-light-color justify-between transition-all rounded-xl  ${location.pathname === "/notification" ? "bg-theme-color text-white active" : "text-black"}   `} onClick={()=>{setLogout(true)}}>
            <Link className="w-full p-3">
              <div className="flex items-center text-sm">
                <FiLogOut size={19} />
                <h2 className=" text-sm font-normal pl-6">Logout</h2>
              </div>
            </Link>
          </li>          
          
          
         
        </ul>
        
        
        {/* <Link to="/">
       <div className="flex gap-3 items-center absolute 4xl:bottom-12  bottom:10 left-6 hover:text-theme-color hover:bg-theme-light-color">
        <FiLogOut className="text-[#5C5F62]"/>
        <h2 className="text-sm font-normal">Logout</h2>
       </div>
       </Link> */}
      </div>
      ): (
         ""
        ) }
    </>
  );
}

export default Sidebar;