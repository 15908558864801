import React, { useEffect } from "react";
import { useContext } from "react";
import { DataContext } from "../../context/DataState";
import { FaNewspaper } from "react-icons/fa"


const Dashboard = () => {
  const { dashboardDataApi , dashboardDataState } = useContext(DataContext);
  useEffect(() => {
    dashboardDataApi();
  }, []);

  //console.log(dashboardDataState , 'dashboardDataState')
  return (
    <>
      <div className=" w-full flex ">
        <div className="w-full px-4 py-5">
          <div className="bg-theme-white w-full">
            <div className="mx-2 ">
              <div className="flex  justify-between  items-center">
                <div>
                  <h1 className=" text-theme-black mt-4 font-semibold  text-[25px] 5xl:w-80 4xl:w-72 3xl:w-72  5xl:h-">Dashboard</h1>
                </div>
              </div>
              <div className="grid grid-cols-3 5xl:pt-[21px] 4xl:pt-[22px] 3xl:pt-[22px]  ">
                <div className="flex shadow-md bg-white 5xl:w-[475px] 5xl:h-[159px] 4xl:w-[325px] 4xl:h-[135px] 3xl:w-[300px] 3xl:h-[125px] gap-[24px] items-center justify-center rounded-2xl">
                  <div >
                    <div className="5xl:h-[59.73px] 5xl:w-[59.73px] flex items-center justify-center bg-[#F5FAFF] ">
                      <FaNewspaper className="text-theme-color" size={33}/>
                    </div>

                  </div>
                  <div className="5xl:py-[42.17px] 5xl:px-[24px] ">
                    <span className="text-[#272D37] font-semibold 5xl:text-[29.33px] 4xl:text-[22px] 3xl:text-[19px] ">{dashboardDataState?.eventCount}</span>
                    <p className="5xl:pt-[8px] 5xl:text-lg 4xl:text-[14px] 3xl:text-[12.75px]">Total Event</p>
                  </div>
                </div>
                <div className="flex shadow-md bg-white 5xl:w-[475px] 5xl:h-[159px] 4xl:w-[325px] 4xl:h-[135px] 3xl:w-[300px] 3xl:h-[125px] gap-[24px] items-center justify-center rounded-2xl">
                  <div >
                    <div className="5xl:h-[59.73px] 5xl:w-[59.73px] flex items-center justify-center bg-[#F5FAFF] ">
                    <FaNewspaper className="text-theme-color" size={33}/>
                    </div>

                  </div>
                  <div className="5xl:py-[42.17px] 5xl:px-[24px] ">
                    <span className="text-[#272D37] font-semibold 5xl:text-[29.33px] 4xl:text-[22px] 3xl:text-[19px] ">{dashboardDataState?.speakerCount}</span>
                    <p className="5xl:pt-[8px] 5xl:text-lg 4xl:text-[14px] 3xl:text-[12.75px]">Total speaker</p>
                  </div>
                </div>


                {/* <div className="flex shadow-md bg-white 5xl:w-[475px] 5xl:h-[159px] 4xl:w-[325px] 4xl:h-[135px] 3xl:w-[300px] 3xl:h-[125px] gap-[24px] items-center justify-center rounded-2xl">
                  <div >
                    <div className="5xl:h-[59.73px] 5xl:w-[59.73px] flex items-center justify-center bg-[#F5FAFF] ">
                      <img className="" src={Icon} alt="" />
                    </div>

                  </div>
                  <div className="5xl:py-[42.17px] 5xl:px-[24px] ">
                    <span className="text-[#272D37] font-semibold 5xl:text-[29.33px] 4xl:text-[22px] 3xl:text-[19px] ">80</span>
                    <p className="5xl:pt-[8px] 5xl:text-lg 4xl:text-[14px] 3xl:text-[12.75px]">Total user</p>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>


    </>
  );
};
export default Dashboard;