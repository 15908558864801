import React from "react";
import { useContext } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { DataContext } from "../../context/DataState";
import { ToastContainer } from "react-toastify";

const RegisterEvent = () => {
  const navigate = useNavigate();
  const { RegisterEventApi, buttonLoader, setButtonLoader } = useContext(DataContext);
  const [state, setState] = useState({
    eventName: '',
    startDate: '',
    endDate: '',
    eventLocation: '',
    timeZone: '',
    userName: '',
    userPhoneNumnber: '',
    userBusinessName: '',
    userEmail: '',
    password: ''
  });

  // Email regex code format test and  error state 
  const [emailFormatTest, setEmailFormatTest] = useState(false);
  const validationMail = (maildata) => {
    var emailreg = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    if (maildata.match(emailreg)) {
      return setEmailFormatTest(false)
    }
    else if (maildata == '') {
      return setEmailFormatTest(false)
    }
    else {
      return setEmailFormatTest(true)
    }
  };


  // Phone-Number Regex code format test and error state
  const [phoneFormatTest, setPhoneFormatTest] = useState(false);
  const validatePhoneno = (phoneData) => {
    var phoneno = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
    if (phoneData.match(phoneno)) {
      return setPhoneFormatTest(false)
    }
    else if (phoneData == '') {
      return setPhoneFormatTest(false)
    }
    else {
      return setPhoneFormatTest(true)

    }
  }


  const buttonDisabled = (state.eventName !== '' && state.startDate !== '' && state.endDate !== '' && state.userName !== '' && state.userEmail !== '' && state.password !== '' && phoneFormatTest === false && emailFormatTest === false)




  const handleEventCreation = () => {
    RegisterEventApi(state);
    setButtonLoader(true);
  };

  return (
    <>
      <ToastContainer />
      <div className="w-full flex bg-white">
        <div className="w-full h-screen">
          <h1 className="font-semibold text-xl ml-8 mt-6">Event Information</h1>
          <div className=" ml-8 mt-[26px] w-full">
            <form action="" className="w-full flex gap-12">
              <div className="w-[45%]">
                <h1 className="font-medium  text-lg ">Event details</h1>
                <hr className="text-[#D9D9D9] mt-2" />
                <div className="mt-4">
                  <label htmlFor="event-name" className="font-medium text-sm text-[#202223]">Event Name*</label>
                  <input type="text" id="event-name" name="event-name" className="w-full border font-normal text-sm border-inputBoxColor outline-none px-3 py-2 rounded-[4px] focus-visible:border-inputBorder  focus-visible:outline-none   focus-visible:shadow-focusshadow focus-visible:border-1" onChange={(e) => { setState({ ...state, eventName: e.target.value }) }} />
                </div>
                <div>
                  <div className="mt-4 flex gap-12 w-full">
                    <div className="w-full">
                      <label htmlFor="start-date" className="font-medium text-sm text-[#202223]">Start date*</label>
                      <input type="date" id="start-date" name="event-name" className="w-full border font-normal text-sm border-inputBoxColor outline-none px-3 py-2 rounded-[4px] focus-visible:border-inputBorder focus-visible:outline-none   focus-visible:shadow-focusshadow focus-visible:border-1" 
                      min={new Date().toISOString().split('T')[0]}
                      onChange={(e) => { setState({ ...state, startDate: e.target.value }) }} />
                    </div>
                    <div className="w-full">
                      <label htmlFor="end-date" className="font-medium text-sm text-[#202223]">End date*</label>
                      <input type="date" id="end-date" name="event-name" className="w-full border font-normal text-sm border-inputBoxColor outline-none px-3 py-2 rounded-[4px] focus-visible:border-inputBorder focus-visible:outline-none   focus-visible:shadow-focusshadow focus-visible:border-1" 
                      min={new Date().toISOString().split('T')[0]}
                      onChange={(e) => { setState({ ...state, endDate: e.target.value }) }} />
                    </div>
                  </div>
                  <p className="font-normal text-sm text-[#4B4B4B]">Once the end date pass you will no longer be able to change dates</p>

                </div>
                <h1 className="font-medium  text-lg mt-4 ">Location and time zone</h1>
                <hr className="text-[#D9D9D9] mt-2" />
                <div className="mt-4">
                  <label htmlFor="location" className="font-medium text-sm text-[#202223]">Location</label>
                  <input type="text" id="location" name="location" className="w-full border font-normal text-sm border-inputBoxColor outline-none px-3 py-2 rounded-[4px] focus-visible:border-inputBorder focus-visible:outline-none   focus-visible:shadow-focusshadow focus-visible:border-1" onChange={(e) => { setState({ ...state, eventLocation: e.target.value }) }} />

                </div>
                <div className="mt-4">
                  <label htmlFor="time-zone" className="font-medium text-sm text-[#202223] focus-visible:outline-none   focus-visible:shadow-focusshadow focus-visible:border-1 focus-visible:border-inputBorder">Time zone</label>
                  <select name="time-zone" id="time-zone" className="w-full border font-normal text-sm border-inputBoxColor outline-none px-3 py-2 rounded-[4px]">
                    <option value="volvo">India/Delhi</option>

                  </select>
                  {/* <input type="" id="time-zone" name="location" className="w-full border font-normal text-sm border-[#AEB4B9] outline-none px-3 py-2 rounded-[4px]" /> */}
                </div>
              </div>

              <div className="w-[45%]">
                <h1 className="font-medium  text-lg ">Personal Details</h1>
                <hr className="text-[#D9D9D9] mt-2" />
                <div className="mt-4">
                  <label htmlFor="full-name" className="font-medium text-sm text-[#202223]">Organiser Name*</label>
                  <input type="text" id="full-name" name="full-name" className="w-full border font-normal text-sm border-inputBoxColor outline-none px-3 py-2 rounded-[4px] focus-visible:outline-none   focus-visible:shadow-focusshadow focus-visible:border-1 focus-visible:border-inputBorder" onChange={(e) => { setState({ ...state, userName: e.target.value }) }} />
                </div>
                <div>
                  <div className="mt-4 flex gap-12 w-full">
                    <div className="w-full">
                      <label htmlFor="phone-number" className="font-medium text-sm text-[#202223]">Phone Number</label>
                      <input type="text" maxLength={10} pattern="[0-9]{10}" value={state.userPhoneNumnber} id="phone-number" name="phone-number" className="w-full border font-normal text-sm border-inputBoxColor outline-none px-3 py-2 rounded-[4px] focus-visible:outline-none   focus-visible:shadow-focusshadow focus-visible:border-1 focus-visible:border-inputBorder" onChange={(e) => { setState({ ...state, userPhoneNumnber: e.target.value }) }} onKeyUp={() => validatePhoneno(state.userPhoneNumnber)} />
                      {phoneFormatTest ? <span className="text-red-600 text-[10px]">Please enter a valid 10 digits Number.</span> : <></>}
                    </div>
                    <div className="w-full">
                      <label htmlFor="business-name" className="font-medium text-sm text-[#202223]">Business Name</label>
                      <input type="text" id="business-name" name="business-name" className="w-full border font-normal text-sm border-inputBoxColor outline-none px-3 py-2 rounded-[4px] focus-visible:outline-none   focus-visible:shadow-focusshadow focus-visible:border-1 focus-visible:border-inputBorder" onChange={(e) => { setState({ ...state, userBusinessName: e.target.value }) }} />
                    </div>
                  </div>
                </div>
                <h1 className="font-medium  text-lg mt-2 ">Access information</h1>
                <p className="font-normal text-sm text-[#4B4B4B] mt-3">These details will be use by Admin to access the portal.</p>
                <hr className="text-[#D9D9D9] mt-2" />
                <div className="mt-4">
                  <label htmlFor="Email" className="font-medium text-sm text-[#202223]">Email*</label>
                  <input type="Email" id="Email" name="Email" value={state.userEmail} className="w-full border font-normal text-sm border-inputBoxColor outline-none px-3 py-2 rounded-[4px] focus-visible:outline-none   focus-visible:shadow-focusshadow focus-visible:border-1 focus-visible:border-inputBorder" onChange={(e) => { setState({ ...state, userEmail: e.target.value }) }} onKeyUp={() => validationMail(state.userEmail)} />
                  {emailFormatTest ? <span className="text-red-600 text-[10px]">Please enter a valid email address.</span> : <></>}
                </div>
                <div className="mt-4">
                  <label htmlFor="password" className="font-medium text-sm text-[#202223]">Password*</label>
                  <input type="text" id="password" name="password" className="w-full border font-normal text-sm border-inputBoxColor outline-none px-3 py-2 rounded-[4px] focus-visible:outline-none   focus-visible:shadow-focusshadow focus-visible:border-1 focus-visible:border-inputBorder" onChange={(e) => { setState({ ...state, password: e.target.value }) }} />
                </div>
              </div>


            </form>
          </div>
          <div className="flex gap-2 mt-8 ml-8">
            <button className="bg-[#FFFFFF] font-medium text-sm px-8 py-2 text-[#202223] rounded-[4px] border border-[#BABFC3]" onClick={() => { navigate('/event') }}>Back</button>
            <button disabled={!buttonDisabled} className="bg-[#FF8531] font-medium text-sm px-8 py-2 text-white disabled:opacity-40 rounded-[4px]" onClick={() => { handleEventCreation() }}>
              {/* <span className={` ${buttonLoader ? "border-t-transparent inline-block border-1 border-white animate-spin rounded-full w-5 h-5" : <></>} `}></span>  */}
              Save</button>
          </div>

        </div>

      </div>


    </>
  )
}

export default RegisterEvent;