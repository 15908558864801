import React, { useContext, useState } from "react";

// import { FaPencilAlt, FaTrashAlt } from "react-icons/fa";
// import { Link } from "react-router-dom";
import speaker1 from "../Assets/speaker1.jpg";
import speaker2 from "../Assets/speaker2.jpg";
import speaker3 from "../Assets/speaker3.png";
import speaker4 from "../Assets/speaker4.png";
import speaker5 from "../Assets/speaker5.png";
import speaker6 from "../Assets/speaker1.jpg";
import { DataContext } from "../../context/DataState";
import isSpeakerModal from "./AddSpeaker"
import AddSpeaker from "./AddSpeaker";




function Speaker() {
  const [openModal, setOpenModal] = useState(false);
  const { addSpeaker, isAddSpeaker } = useContext(DataContext)

  return (
    <>
      <div className="w-full flex">
        {
          addSpeaker ? <AddSpeaker /> : ""
        }
        <div className="w-4/5 px-7 py-2">
          <div className="flex justify-between items-center">
            <h1 className="font-poppins font-semibold text-[25px]  leading-[68px] text-title-color">Speaker</h1>
            <button type="button" className="bg-theme-color text-white text-md p-1 rounded" onClick={() => isAddSpeaker(!addSpeaker)}>Add Speaker</button>
          </div>
          <div className="bg-white rounded-xl ">
            <div className="px-10 py-10 gap-12 grid grid-cols-4 text-center place-items-center">
              <div className="p-4 ">
                <span><img className="rounded-full w-[100px] h-[100px]" src={speaker1} alt=""  /></span>
                <h1 className="font-extrabold text-md ">Phil Bailey</h1>
                <span className=" w-full inline-block text-md ">play</span>
              </div>
              <div className="p-4">
                <span><img className="rounded-full w-[100px] h-[100px]" src={speaker2} alt="" /></span>
                <h1 className="font-extrabold text-md ">Scott Douglas</h1>
                <span className="w-full inline-block text-md">WaveBox</span>
              </div>
              <div className="p-4 ">
                <span><img className="rounded-full w-[100px] h-[100px]" src={speaker3} alt="" /></span>
                <h1 className="font-extrabold text-md ">Amber Holmes</h1>
                <span className="w-full inline-block text-md">Nostrud</span>
              </div>
              <div className="p-4">
                <span><img className="rounded-full w-[100px] h-[100px]" src={speaker4} alt="" /></span>
                <h1 className="font-extrabold text-md ">Phil Bailey</h1>
                <span className="w-full inline-block text-md">Nostrud</span>
              </div>
              <div className="p-4">
                <span><img className="rounded-full w-[100px] h-[100px]" src={speaker5} alt="" /></span>
                <h1 className="font-extrabold text-md ">Phil Bailey</h1>
                <span className=" w-full inline-block text-md">play</span>
              </div>
              <div className="p-4">
                <span><img className="rounded-full w-[100px] h-[100px]" src={speaker6} alt="" /></span>
                <h1 className="font-extrabold text-md ">Scott Douglas</h1>
                <span className="w-full inline-blocktext-md">WaveBox</span>
              </div>
              <div className="p-4 ">
                <span><img className="rounded-full w-[100px] h-[100px]" src={speaker2} alt="" /></span>
                <h1 className="font-extrabold text-md ">Amber Holmes</h1>
                <span className="w-full inline-blocktext-md">Nostrud</span>
              </div>
              <div className="p-4">
                <span><img className="rounded-full w-[100px] h-[100px]" src={speaker5} alt="" /></span>
                <h1 className="font-extrabold text-md ">Phil Bailey</h1>
                <span className="w-full inline-block text-md">Nostrud</span>
              </div>
            </div>
          </div>
          {/* <div className="flex justify-between items-center ">
            <h1 className="font-poppins font-semibold text-[25px]  leading-[68px] text-title-color">Speaker</h1>
            <button type="button" className="bg-theme-blue text-white text-md p-2 rounded" onClick={() => setOpenModal(!openModal)}>Add Speaker</button>
          </div>
          <div className="rounded-lg mx-2 mt-[10px] h-[77.4vh] overflow-y-scroll scrollbar-none">
            <table className=" w-full whitespace-nowrap rounded-lg shadow-xl bg-white">
              <thead className="sticky top-0 left-0 w-full">
                <tr className=" text-black h-[32px] text-[15px] font-medium leading-[13.5px] tracing-[-0.08px]">
                  <th className="border border-table-color p-2">Image</th>
                  <th className="border border-table-color p-2">Name</th>
                  <th className="border border-table-color p-2">Designation</th>
                  <th className="border border-table-color p-2">Description</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="border border-table-color p-1 ">image</td>
                  <td className="border border-table-color p-1 ">Alok</td>
                  <td className="border border-table-color p-1">Frontend</td>
                  <td className="border border-table-color p-1">Description</td>
                </tr>
                <tr>
                  <td className="border border-table-color p-1 ">image</td>
                  <td className="border border-table-color p-1 ">Alok</td>
                  <td className="border border-table-color p-1">Frontend</td>
                  <td className="border border-table-color p-1">Description</td>
                </tr>
                <tr>
                  <td className="border border-table-color p-1 ">image</td>
                  <td className="border border-table-color p-1 ">Alok</td>
                  <td className="border border-table-color p-1">Frontend</td>
                  <td className="border border-table-color p-1">Description</td>
                </tr>
              </tbody>
            </table>
          </div> */}
        </div>
      </div>
    </>
  )
}
export default Speaker;