import React from "react";
import { RxPencil1 } from "react-icons/rx";
import { AiOutlineDelete } from "react-icons/ai";
import { FiTrash,FiEdit2 } from 'react-icons/fi'
import { DataContext } from "../../context/DataState";
import { useContext } from "react";
import { useEffect } from "react";
import GlobalLoader from "../GlobalLoader/GlobalLeader";
import DeleteModal from "../DeleteModal/DeleteModal";


const Admin = () => {
  const { globalLoader, EventAdminApi, eventAdminState, deleteBtn,setDeleteBtn,setDeleteBtnId, deleteBtnId } = useContext(DataContext);

  useEffect(() => {
    EventAdminApi();
  }, []);


  return (
    <>
      {deleteBtn ? <DeleteModal deleteApi={""} deleteId={deleteBtnId}/> : ""}
      <div className="w-full flex bg-white">
           <div className="w-full px-5 py-2">
          <div className="flex justify-between items-center py-2 ">
            <h1 className="font-poppins font-semibold text-xl leading-10 text-title-color">
              Admins
            </h1>
          </div>
          {
            globalLoader ? <GlobalLoader /> :
              <div className="w-full bg-[#FAFBFB] rounded-lg shadow-adminTbl mt-4">
                <table className="border border-white w-full border-separate ">
                  <thead className="cursor-pointer" >
                    <tr className="text-[12px] text-[#6D7175] text-left font-normal h-[32px] whitespace-nowrap ">
                      <th className="border-b border-white px-4 py-2">
                        Full Name
                      </th>
                      <th className="border-b border-white px-4 py-2">
                        Email address
                      </th>
                      <th className="border-b border-white px-4 py-2">
                        Business Name
                      </th>
                      <th className="border-b border-white px-4 py-2">
                        Phone Number
                      </th>
                      {/* <th className="border-b border-white px-4 py-2 text-center">Action</th> */}
                    </tr>
                  </thead>
                  <tbody className="[&>*:nth-child(even)]:bg-[#FAFBFB]">
                    {
                      eventAdminState && eventAdminState.map((item) => (
                        <tr className="bg-white group font-normal text-[12px] cursor-pointer text-left group/item hover:shadow-tableRowsShadow ">
                          <td className="border-b border-white px-4 py-2 text-[#202223]">
                            {item?.Name}
                          </td>
                          <td className="border-b border-white px-4 py-2 text-[#202223]">
                            {item?.email}
                          </td>
                          <td className="border-b border-white px-4 py-2 text-[#202223]">
                            {
                              item?.organization
                            }
                          </td>
                          <td className="border-b border-white px-4 py-2 text-[#202223]">
                            {item?.phone}
                          </td>
                          <td> 
                            <div className="flex items-center  justify-center  gap-2 text-black"> 
                              {/* <FiEdit2 className="hover:text-theme-color hover:bg-theme-light-color hover:rounded-full px-[6px] py-0 text-[28px]"/>  */}
                              {/* <FiTrash className="hover:text-theme-color hover:bg-theme-light-color hover:rounded-full px-[6px] py-0 text-[28px]"
                              onClick={()=>{setDeleteBtn(true)
                                setDeleteBtnId(item?._id)
                                }} />  */}
                            </div>
                          </td>
                        </tr>
                      ))
                    }

                  </tbody>
                </table>
              </div>
          }
        </div>

      </div>
    </>
  );
};

export default Admin;
